<template>
  <!-- Page Content  -->
  <b-container fluid>
    <div class="d-flex align-items-center justify-content-between">
      <h4>Orders</h4>
      <div @click="addTask(item)">
        <button class="btn btn-outline-primary text-nowrap d-none d-sm-inline"><span class="mr-3">New Order</span><span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="19" viewBox="0 0 19 19" fill="none">
          <path d="M10.336 0.426224L10.336 18.5738M18.91 10H0.762451" stroke="#0071BB"/>
          </svg></span>
        </button>
        <i class="d-block d-sm-none" type="button"><span><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 19 19" fill="none">
          <path d="M10.336 0.426224L10.336 18.5738M18.91 10H0.762451" stroke="#0071BB"/>
          </svg></span></i>
      </div>
    </div>
    <hr/>
      <b-row>
        <b-col md="12" lg="3">
          <iq-card class="iq-card iq-card-block iq-card-stretch iq-card-height">
            <template v-slot:body>
              <div class="align-items-center mt-2">
                <h6>Balance sheet</h6>
                <div class="table-responsive my-3">
                  <table class="list">
                    <thead>
                      <tr>
                        <th>LCY</th>
                        <th>Balance in USD</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>USD</td>
                        <td>1,813,641.96</td>
                      </tr>
                      <tr>
                        <td>EUR</td>
                        <td>-5,462,120.00</td>
                      </tr>
                      <tr>
                        <td>GBX</td>
                        <td>-62,750.00</td>
                      </tr>
                      <tr>
                        <td>SEK</td>
                        <td>1,813,641.96</td>
                      </tr>
                      <tr>
                        <td>GBP</td>
                        <td>-5,462,120.00</td></tr>
                      <tr>
                        <td>ZAR</td>
                        <td>-62,750.00</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <hr class="mx-2 mt-5" />
              <div class="px-2 portofilo">
                <div class="portofilovalue">
                  <div class="mb-3">
                    <li class="text-gray">Total Investment (USD)</li>
                    <li><b>4,58,306.57</b></li>
                </div>
                  <div class="text-gray mb-3">
                  <li>Current Value (USD)</li>
                  <li class="text-success">10,03,470.14</li>
              </div>
              <div class="text-gray">
                  <li>Total P&L (USD)</li>
                  <li class="text-success">10,03,470.14</li>
                </div>
                </div>
                <div class="ml-2 d-none d-lg-block">
                  <a class="text-info">Fund</a>
              </div>
              </div>
              <div class="d-flex justify-content-end text-gray"><small>Updated on 26-12-2022 15:30</small></div>
             </template>
          </iq-card>
        </b-col>
        <b-col md="12" lg="9">
          <b-row>
          <b-col sm="12">
            <iq-card class="iq-card-block iq-card-stretch iq-card-height p-2">
              <template v-slot:body>
                <div class="table-responsive table-responsive-sm">
                <table class="table mb-0 table-borderless">
                  <thead>
                  <tr>
                    <th scope="col">Status</th>
                    <th scope="col">Trade Date</th>
                    <th scope="col">Instrument</th>
                    <th scope="col">Qty.</th>
                    <th scope="col">Type</th>
                    <th scope="col">Trade Price</th>
                    <th scope="col"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td class="text-danger">Pending</td>
                    <td>19-12-2022</td>
                    <td><small>EUR</small><br/>AGS BB EQUITY</td>
                    <td>475.34</td>
                    <td><div class="badge badge-pill badge-danger">SELL</div></td>
                    <td>1.00643749</td>
                    <td><a>Cancel </a></td>
                  </tr>
                  <tr>
                    <td class="text-danger">Pending</td>
                    <td>15-12-2022</td>
                    <td><small>EUR</small><br/>AGS BB EQUITY</td>
                    <td>475.34</td>
                    <td><div class="badge badge-pill badge-success">BUY</div></td>
                    <td>1.00643749</td>
                    <td><a>Cancel </a></td>
                  </tr>
                  <tr>
                    <td class="text-success">Success</td>
                    <td>19-12-2022</td>
                    <td><small>EUR</small><br/>AKAM US EQ</td>
                    <td>10,000.00</td>
                    <td><div class="badge badge-pill badge-danger">SELL</div></td>
                    <td>1.00643749</td>
                    <td><a>Cancel </a></td>
                  </tr>
                  <tr>
                    <td class="text-success">Success</td>
                    <td>15-12-2022</td>
                    <td><small>EUR</small><br/>AKAM US EQ</td>
                    <td>10,000.00</td>
                    <td><div class="badge badge-pill badge-success">BUY</div></td>
                    <td>1.0002356896</td>
                    <td><a>Cancel </a></td>
                  </tr>
                  </tbody>
                </table>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
        </b-col>
        <b-modal id="task" variant="dark" centered title="Create New Order" hide-footer>
          <b-form @submit.prevent="saveTask(task)">
            <b-form-group  v-slot="{ ariaDescribedby }">
              <b-row>
                <b-col sm="2"><b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios"  class="bye" value="A"><span style="color:green">Buy</span></b-form-radio></b-col>
              <b-col sm="1"><b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" variant="danger" value="B"><span style="color:red">Sell</span></b-form-radio></b-col>
              </b-row>
          </b-form-group>
          <b-row>
              <b-col lg="6">
                <b-form-group label="Security Name">
                  <b-input placeholder="eg: APPL" />
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="ISIN">
                  <b-input placeholder="eg: AP1256532"  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group >
            <b-form-radio-group
                id="term"
                name="term"
                :options="term"
              />
          </b-form-group>
            <b-form-group label="Qty.">
              <b-input placeholder="eg: 100" />
            </b-form-group>
            <b-form-group >
            <b-form-radio-group
                id="range"
                name="range"
                :options="range"
              />
          </b-form-group>
            <b-form-group label="Price">
              <b-input placeholder="eg:1.999" />
            </b-form-group>
            <div class="d-flex justify-content-end">
              <b-button variant="primary ml-2"  class="iq-waves-effect" type="submit">Create Order</b-button>
            </div>
          </b-form>
        </b-modal>
      </b-row>
  </b-container>
</template>
<script>
import { core } from '../../config/pluginInit'
export default {
  name: 'Dashboard4',
  mounted () {
    core.index()
  },
  data () {
    return {
      order: [
        { text: 'Buy', value: 0, color: 'text-success' },
        { text: 'Sell', value: 1, color: 'text-danger' }
      ],
      term: [
        { text: 'Intraday', value: 0 },
        { text: 'GTC', value: 1 },
        { text: 'GTD', value: 2 }
      ],
      range: [
        { text: 'Market', value: 0, color: 'text-success' },
        { text: 'Limit', value: 1, color: 'text-danger' }
      ]
    }
  },
  methods: {
    addTask () {
      this.$bvModal.show('task')
    }
  }
}
</script>
<style>
label,legend{
  color: black;
}
</style>
